import React from "react";

type TypeWriterProps = {
  targets: string[];
};

type TypeWriterState = {
  target: number;
  text: string;
  character: number;
  increment: number;
  pausedFor: number;
};

class TypeWriter extends React.Component<TypeWriterProps, TypeWriterState> {
  private timer: any;

  constructor(props: TypeWriterProps) {
    super(props);
    this.state = {
      target: 0,
      text: "Amazon",
      character: 7,
      increment: 1,
      pausedFor: 0,
    };
  }

  componentDidMount() {
    let text = { ...this.state };
    const deferred = () => {
      if (text.pausedFor === 0) {
        text.character += text.increment;

        if (text.character > this.props.targets[text.target].length) {
          text.pausedFor = 1;
          text.character = this.props.targets[text.target].length;
        } else if (text.character <= 0) {
          text.pausedFor = 1;
          text.character = 0;
          text.target = (text.target + 1) % this.props.targets.length;
        }
      } else {
        text.pausedFor += 1;
        if (text.pausedFor >= 9) {
          text.increment = -text.increment;
          text.pausedFor = 0;
        }
      }

      text.text = this.props.targets[text.target].slice(0, text.character);

      this.setState(text);
    };

    this.timer = setInterval(deferred, 150);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    return (
      <>
        <span>{this.state.text}</span>

        <style jsx>{`
          span {
            border-radius: 0;
            overflow: hidden;
            border-right: 0.15em solid var(--chakra-colors-blackAlpha-500);
            white-space: nowrap;
            margin: 0 auto;
            letter-spacing: 0.15em;
            animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
          }

          @keyframes blink-caret {
            from,
            to {
              border-color: transparent;
            }
            50% {
              border-color: var(--chakra-colors-blackAlpha-500);
            }
          }
        `}</style>
      </>
    );
  }
}

export default TypeWriter;
