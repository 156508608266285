import * as React from "react";

export function WaveTop01(props) {
  return (
    <svg
      viewBox="0 0 1920 768"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <path
        d="M0 540s437.787 114.707 717.498 75.445c279.711-39.262 436.822-274.452 685.162-327.955C1577.6 249.802 1920 330.601 1920 330.601V0H0v540z"
        fill={props.fill || "var(--primary)"}
        style={{
          filter: "drop-shadow(8px 8px 10px hsla(90, 10%, 10%, 20%))",
        }}
      />
    </svg>
  );
}
