import { ParentSize, ScaleSVG } from "@visx/responsive";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { Scrollama, Step } from "react-scrollama";

import Layout from "src/components/layouts/Default";

import { WaveTop01 } from "shared/assets/graphics/Waves";
import Landing01_Left from "shared/assets/graphics/landing_01_left.png";
import Landing01_Right from "shared/assets/graphics/landing_01_right.png";
import Landing02 from "shared/assets/graphics/landing_02.png";
import Landing03 from "shared/assets/graphics/landing_03.png";
import Landing04 from "shared/assets/graphics/landing_04.png";
import Landing05 from "shared/assets/graphics/landing_05.png";
import thumbnail from "shared/assets/images/props_01.png";
import TypeWriter from "shared/components/TypeWriter";

enum STEP {
  HERO,
  ONLINE_GIANTS,
  LOCAL_STORES,
  ONLINE_VISIBILITY,
  SOLUTION,
  VIDEO,
}

const CTAs = [
  "A browser extension to get you off Amazon and into your local shops.",
  "A simple browser extension that encourages you to shop locally.",
  "A web browser extension that automatically alerts online shoppers, searching on sites like Amazon and Google, of local stores that sell the same items they’re looking for.",
];

const Hero = () => (
  <>
    <div className="container">
      <div className="wave">
        <WaveTop01 />
      </div>
      <div className="text">
        <h2>
          <span>
            A&nbsp;browser&nbsp;extension <wbr />
            to&nbsp;get&nbsp;you&nbsp;off
          </span>
          <br />
          <span className="typewriter">
            <TypeWriter targets={["Amazon", "Ebay", "Google"]} />
          </span>
          <br />
          <span>
            and&nbsp;into&nbsp;your <wbr />
            local&nbsp;shops.
          </span>
        </h2>
      </div>
      <div className="image image-left">
        <Image src={Landing01_Left} width={1200} height={996} priority />
      </div>
      <div className="image image-right">
        <Image src={Landing01_Right} width={1200} height={971} priority />
      </div>
    </div>
    <style jsx>{`
      .container {
        height: 100%;
        display: grid;
        grid-template-rows: 0.5fr 1fr 1fr 0.2fr;
        grid-template-columns: 1fr [edge-l] 2fr [mid-l] 1fr [mid-r] 2fr [edge-r] 1fr;
        line-height: 1;
      }
      @media screen and (max-width: 600px) {
        .container {
          grid-template-rows: 0.5fr 1fr 1fr 0.2fr;
          grid-template-columns: 0.2fr [edge-l] 2fr [mid-l] 3fr [mid-r] 2fr [edge-r] 0.2fr;
        }
      }
      h2 {
        font-size: 1.6em;
        font-weight: 700;
      }
      .wave {
        grid-column: 1/6;
        grid-row: 1/5;
      }
      .text {
        text-align: center;
        z-index: 2;
        grid-row: 2/4;
        grid-column: 1/6;
        align-self: start;
        color: var(--shadow);
        filter: drop-shadow(8px 8px 10px hsla(90, 10%, 10%, 20%));
      }
      .image {
        z-index: 1;
        filter: drop-shadow(8px 8px 10px hsla(90, 10%, 10%, 20%));
        max-width: 20em;
      }
      .image-left {
        grid-row: 2/4;
        grid-column: edge-l / mid-r;
        align-self: center;
        justify-self: left;
      }
      .image-right {
        grid-row: 2/4;
        grid-column: mid-l / edge-r;
        align-self: end;
        justify-self: right;
      }
      span {
        white-space: break-spaces;
        word-break: break-word;
        color: var(--shadow);
        padding: 0.2em 0.6em;
        margin: 0.1em;
        display: inline-block;
        border-radius: 0.75em;
      }
      span:first-child {
        background-color: var(--chakra-colors-whiteAlpha-800);
      }
      span:not(:first-child):not(:last-child) {
        color: var(--primary);
        background-color: var(--highlight);
        width: 8em;
      }
      span:last-child {
        background-color: var(--chakra-colors-whiteAlpha-800);
      }
    `}</style>
  </>
);

const Trail = () => (
  <div>
    <ParentSize>
      {({ width, height }) => {
        const bit = width / 10;
        const left = bit;
        const right = width - bit;
        const middle = width / 2;
        const sectionHeight = height / 7;
        const offset = sectionHeight / 2 + bit;

        const startX1 = middle;
        const startY1 = offset;
        const startX2 = middle;
        const startY2 = offset + sectionHeight;
        const startX3 = left + bit;
        const startY3 = offset + sectionHeight * 2.5;
        const startX4 = right - bit;
        const startY4 = offset + sectionHeight * 3.35;

        return (
          <ScaleSVG width={width} height={height}>
            <circle cx={startX1} cy={startY1} fill="var(--alternate)" r="50" />
            <path
              fill="none"
              stroke="var(--alternate)"
              strokeLinecap="round"
              strokeWidth="1em"
              strokeDasharray="25 50"
              d={`M ${startX1} ${startY1} C ${middle - bit} ${startY1 + bit * 2} ${middle - bit} ${
                startY1 + bit * 2
              } ${startX2} ${startY2}`}
            />

            <path
              fill="none"
              stroke="var(--primary-lighter-a)"
              strokeLinecap="round"
              strokeWidth="1em"
              strokeDasharray="25 50"
              d={`M ${startX2} ${startY2} C ${middle + 100} ${offset + sectionHeight * 2} ${left} ${
                offset + sectionHeight * 2
              } ${startX3} ${startY3}`}
            />
            <circle cx={startX2} cy={startY2} fill="var(--alternate)" r="50" />

            <path
              fill="none"
              stroke="var(--primary-lighter-a)"
              strokeLinecap="round"
              strokeWidth="1em"
              strokeDasharray="25 50"
              d={`M ${startX3} ${startY3} C ${startX3} ${startY4 - bit} ${right} ${
                startY3 + bit
              } ${startX4} ${startY4}`}
            />

            <circle cx={startX3} cy={startY3} fill="var(--primary-lighter)" r="50" />
            <circle cx={startX4} cy={startY4} fill="var(--primary-lighter)" r="50" />
          </ScaleSVG>
        );
      }}
    </ParentSize>
    <style jsx>{`
      div {
        height: 100%;
      }
      svg {
        grid-row: 2/3;
        grid-column: 2/3;
      }
      path {
        filter: drop-shadow(8px 8px 10px hsla(90, 10%, 10%, 50%));
      }
    `}</style>
  </div>
);

const OnlineGiants = () => (
  <>
    <div className="container">
      <div className="image">
        <Image src={Landing02} width={1200} height={697} />
      </div>
      <div className="text">
        <h2 color="snow">
          <span>Online giants are increasingly</span>
          <br />
          <span>dominating the UK retail market,</span>
          <br />
          <span>while brick &amp; mortar shops suffer.</span>
        </h2>
      </div>
    </div>
    <style jsx>{`
      .text {
        filter: drop-shadow(8px 8px 10px hsla(90, 10%, 10%, 20%));
        text-align: right;
      }
      .image {
        max-width: 32em;
        filter: drop-shadow(8px 8px 10px hsla(90, 10%, 10%, 20%));
      }
      h2 {
        color: var(--highlight);
        font-size: min(max(1rem, 5.5vw), 32px);
        font-weight: 700;
      }
      span {
        background-color: var(--primary);
        padding: 0.2em 0.6em;
        display: inline-block;
        --border-radius: 1em;
      }
      span:first-child {
        border-top-right-radius: var(--border-radius);
        border-top-left-radius: var(--border-radius);
      }
      span:not(:first-child):not(:last-child) {
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
      }
      span:last-child {
        border-top-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
      }
      @media screen and (min-width: 600px) {
        .container {
          height: 100%;
          display: grid;
          grid-template-columns: 1fr [edge-l] 1fr 1fr [mid-l] 2fr [mid-r] 1fr 1fr [edge-r] 1fr;
          grid-template-rows: 0.2fr [edge-t] 1fr [mid-t] 1fr 2fr 2fr [mid-b] 0.2fr [edge-b] 0.2fr;
          align-items: center;
        }
        .text {
          width: 100%;
          grid-column: 1 / edge-r;
          grid-row: 1/5;
          white-space: nowrap;
        }
        .image {
          grid-column: edge-l / edge-r;
          grid-row: 2/6;
          text-align: right;
          align-self: end;
        }
      }
      @media screen and (max-width: 600px) {
        .container {
          height: 100%;
          display: grid;
          grid-template-columns: 0.1fr [edge-l] 1fr 1fr [mid-l] 2fr [mid-r] 1fr 1fr [edge-r] 0.1fr;
          grid-template-rows: 1fr [edge-t] 1fr 0.3fr [mid-t] 0.5fr [mid-b] 1fr 1fr [edge-b] 0.2fr;
          justify-content: center;
        }
        .text {
          grid-column: edge-l / edge-r;
          grid-row: edge-t / mid-b;
          align-self: baseline;
        }
        .image {
          grid-column: edge-l / edge-r;
          grid-row: mid-t / edge-b;
          align-self: start;
        }
      }
    `}</style>
  </>
);

const LocalStores = () => (
  <div className="container">
    <div className="text">
      <h2>
        <span>A huge number of shoppers want to support local retailers </span>
        <br />
        <span>but are often unaware that nearby stores </span>
        <br />
        <span>sell the items they're searching for.</span>
      </h2>
    </div>
    <div className="image">
      <Image src={Landing03} width={1200} height={1113} />
    </div>

    <style jsx>{`
      .text {
        z-index: 2;
        filter: drop-shadow(8px 8px 10px hsla(90, 10%, 10%, 20%));
      }
      .image {
        max-width: 20em;
        filter: drop-shadow(8px 8px 10px hsla(90, 10%, 10%, 20%));
      }
      h2 {
        font-size: min(max(1rem, 3vw), 28px);
        font-weight: 700;
      }
      @media screen and (min-width: 600px) {
        span {
          background-color: var(--alternate);
          padding: 0.2em 0.6em;
          display: inline-block;
          --border-radius: 1em;
        }
        span:first-child {
          border-top-right-radius: var(--border-radius);
          border-top-left-radius: var(--border-radius);
          border-bottom-right-radius: var(--border-radius);
        }
        span:not(:first-child):not(:last-child) {
          border-bottom-right-radius: var(--border-radius);
        }
        span:last-child {
          border-bottom-right-radius: var(--border-radius);
          border-bottom-left-radius: var(--border-radius);
        }
        .container {
          height: 100%;
          display: grid;
          grid-template-columns: 1fr [edge-l] 1fr 1fr [mid-l] 2fr [mid-r] 1fr 1fr [edge-r] 1fr;
          grid-template-rows: 0.2fr [edge-t] 1fr [mid-t] 1fr 2fr 1fr [mid-b] 1fr [edge-b] 0.2fr;
          align-items: center;
        }
        .text {
          text-align: left;
          width: 100%;
          grid-column: edge-l / edge-r;
          grid-row: 1/5;
          white-space: nowrap;
        }
        .image {
          grid-column: mid-l / edge-r;
          grid-row: mid-t / edge-b;
          text-align: right;
          align-self: end;
          justify-self: end;
          max-width: 26em;
        }
      }
      @media screen and (max-width: 600px) {
        .text {
          background-color: var(--alternate);
          border-radius: 1em;
          padding: 0.2em 0.6em;
        }
        h2 {
          font-size: min(max(1rem, 5.2vw), 32px);
        }
        br {
          display: none;
        }
        .container {
          height: 100%;
          display: grid;
          grid-template-columns: 0.2fr [edge-l] 1fr 1fr [mid-l] 2fr [mid-r] 1fr 1fr [edge-r] 0.2fr;
          grid-template-rows: 0.2fr [edge-t] 1fr 1fr [mid-t] 0.5fr [mid-b] 1fr 1fr [edge-b] 0.2fr;
          justify-content: center;
        }
        .text {
          grid-column: edge-l / edge-r;
          grid-row: edge-t / mid-b;
          align-self: center;
          text-align: center;
          justify-self: center;
        }
        .image {
          grid-column: edge-l / edge-r;
          grid-row: mid-t / edge-b;
          align-self: end;
          max-width: 30em;
        }
      }
    `}</style>
  </div>
);

const OnlineVisibility = () => (
  <div className="container">
    <div className="text">
      <h2>
        <span>This is because traditional, physical retailers </span>
        <br />
        <span>can’t compete with the e&#8209;commerce giants </span>
        <br />
        <span>when it comes to online visibility. </span>
      </h2>
    </div>
    <div className="image">
      <Image src={Landing04} width={1200} height={790} />
    </div>

    <style jsx>{`
      .text {
        z-index: 2;
        filter: drop-shadow(8px 8px 10px hsla(90, 10%, 10%, 20%));
      }
      .image {
        max-width: 20em;
        filter: drop-shadow(8px 8px 10px hsla(90, 10%, 10%, 20%));
      }
      h2 {
        color: var(--highlight);
        font-size: min(1.2em, 5vw);
        font-weight: 700;
      }
      @media screen and (min-width: 600px) {
        span {
          background-color: var(--primary-lighter);
          padding: 0.2em 0.6em;
          display: inline-block;
          --border-radius: 1em;
        }
        span:first-child {
          border-top-right-radius: var(--border-radius);
          border-top-left-radius: var(--border-radius);
          border-bottom-right-radius: var(--border-radius);
        }
        span:not(:first-child):not(:last-child) {
          border-top-right-radius: var(--border-radius);
          border-bottom-right-radius: var(--border-radius);
        }
        span:last-child {
          border-bottom-right-radius: var(--border-radius);
          border-bottom-left-radius: var(--border-radius);
        }
        .container {
          height: 100%;
          display: grid;
          grid-template-columns: 1fr [edge-l] 1fr 1fr [mid-l] 2fr [mid-r] 1fr 1fr [edge-r] 1fr;
          grid-template-rows: 0.2fr [edge-t] 1fr [mid-t] 1fr 2fr 1fr [mid-b] 1fr [edge-b] 0.2fr;
          align-items: center;
        }
        .text {
          text-align: left;
          width: 100%;
          grid-column: edge-l / edge-r;
          grid-row: 1/5;
          white-space: nowrap;
        }
        .image {
          grid-column: edge-l / edge-r;
          grid-row: mid-t / edge-b;
          text-align: right;
          align-self: center;
          justify-self: end;
          max-width: 32em;
        }
      }
      @media screen and (max-width: 600px) {
        .text {
          background-color: var(--primary-lighter);
          padding: 0.2em 0.6em;
          border-radius: 1em;
        }
        br {
          display: none;
        }
        .container {
          height: 100%;
          display: grid;
          grid-template-columns: 0.2fr [edge-l] 1fr 1fr [mid-l] 2fr [mid-r] 1fr 1fr [edge-r] 0.2fr;
          grid-template-rows: 0.2fr [edge-t] 1fr 1fr [mid-t] 0.5fr [mid-b] 1fr 1fr [edge-b] 0.2fr;
          justify-content: center;
        }
        .text {
          grid-column: edge-l / edge-r;
          grid-row: edge-t / mid-b;
          align-self: center;
          text-align: center;
        }
        .image {
          grid-column: edge-l / edge-r;
          grid-row: mid-t / edge-b;
          align-self: center;
          max-width: 26em;
        }
      }
    `}</style>
  </div>
);

const Solution = () => (
  <div className="container">
    <div className="text">
      <h2>
        <span>The solution is Lokalyze, a browser extension that automatically</span>
        <br />
        <span>recognises when a user is shopping online and pops up to provide</span>
        <br />
        <span>a list of nearby shops that sell the same or similar items</span>
      </h2>
    </div>
    <div className="image">
      <Image src={Landing05} width={1200} height={699} />
    </div>

    <style jsx>{`
      .text {
        z-index: 2;
        filter: drop-shadow(8px 8px 10px hsla(90, 10%, 10%, 20%));
      }
      .image {
        max-width: 20em;
        filter: drop-shadow(8px 8px 10px hsla(90, 10%, 10%, 20%));
      }
      h2 {
        color: var(--highlight);
        font-size: min(1.2em, 5vw);
        font-weight: 700;
      }
      @media screen and (min-width: 600px) {
        span {
          background-color: var(--primary-lightest);
          padding: 0.2em 0.6em;
          display: inline-block;
          --border-radius: 1em;
        }
        span:first-child {
          border-top-right-radius: var(--border-radius);
          border-top-left-radius: var(--border-radius);
        }
        span:not(:first-child):not(:last-child) {
          border-top-right-radius: var(--border-radius);
          border-bottom-right-radius: var(--border-radius);
        }
        span:last-child {
          border-bottom-right-radius: var(--border-radius);
          border-bottom-left-radius: var(--border-radius);
        }
        .container {
          height: 100%;
          display: grid;
          grid-template-columns: 1fr [edge-l] 1fr 1fr [mid-l] 2fr [mid-r] 1fr 1fr [edge-r] 1fr;
          grid-template-rows: 0.2fr [edge-t] 1fr [mid-t] 1fr 2fr 1fr [mid-b] 1fr [edge-b] 0.5fr;
          align-items: center;
        }
        .text {
          text-align: left;
          width: 100%;
          grid-column: edge-l / edge-r;
          grid-row: 5/7;
          align-self: center;
        }
        .image {
          grid-column: edge-l / edge-r;
          grid-row: edge-t / edge-b;
          text-align: right;
          align-self: center;
          justify-self: end;
          max-width: 32em;
        }
      }
      @media screen and (max-width: 600px) {
        .text {
          background-color: var(--primary-lightest);
          padding: 0.2em 0.6em;
          display: inline-block;
          border-radius: 1em;
        }
        .container {
          height: 100%;
          display: grid;
          grid-template-columns: 0.2fr [edge-l] 1fr 1fr [mid-l] 2fr [mid-r] 1fr 1fr [edge-r] 0.2fr;
          grid-template-rows: 0.2fr [edge-t] 1fr 1fr [mid-t] 0.5fr [mid-b] 1fr 1fr [edge-b] 0.2fr;
          justify-content: center;
        }
        .text {
          grid-column: edge-l / edge-r;
          grid-row: edge-t / mid-b;
          align-self: center;
          text-align: center;
        }
        .image {
          grid-column: edge-l / edge-r;
          grid-row: mid-t / edge-b;
          align-self: center;
          justify-self: center;
          max-width: 24em;
        }
      }
    `}</style>
  </div>
);

const Video = () => {
  return (
    <>
      <div className="">
        <video
          poster={((thumbnail as any) as { src: string }).src}
          src="promo.mp4"
          controls={true}
        />
      </div>

      <style jsx>{`
        div {
          height: 100vh;
          width: 100%;
          display: grid;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        video {
          max-height: 80vh;
        }
      `}</style>
    </>
  );
};

const Page = () => {
  const router = useRouter();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const cta = Math.round((parseInt(router.query.hero as string) || 0) % CTAs.length);

  const onStepEnter = ({ data }) => {
    console.log("current step index", data);
    setCurrentStepIndex(data);
  };

  return (
    <Layout bare footer={true} navHighlight={"var(--primary)"} navLogoWhite={true}>
      <main>
        <Scrollama onStepEnter={onStepEnter} offset={0.0} threshold={5}>
          <Step>
            <div className="trail">
              <Trail />
            </div>
          </Step>

          {/* 

          HERO
          
          */}
          <Step>
            <div className="fill fill-hero" />
          </Step>

          <Step data={STEP.HERO}>
            <section className="full step step-hero">
              <Hero />
            </section>
          </Step>

          {/* 
          
            ONLINE GIANTS
          
          */}

          <Step>
            <div className="fill fill-online-giants" />
          </Step>
          <Step data={STEP.ONLINE_GIANTS}>
            <section className="full step step-online-giants">
              <OnlineGiants />
            </section>
          </Step>

          {/* 

            LOCAL STORES

          */}

          <Step>
            <div className="fill fill-local-stores" />
          </Step>
          <Step data={STEP.LOCAL_STORES}>
            <section className="full step step-local-stores">
              <LocalStores />
            </section>
          </Step>

          {/* 

            ONLINE VISIBILITY

          */}

          <Step>
            <div className="fill fill-online-visibility" />
          </Step>
          <Step data={STEP.ONLINE_VISIBILITY}>
            <section className="full step step-online-visibility">
              <OnlineVisibility />
            </section>
          </Step>

          {/* 

            THE SOLUTION

          */}

          <Step>
            <div className="fill fill-solution" />
          </Step>
          <Step data={STEP.SOLUTION}>
            <section className="full step step-solution">
              <Solution />
            </section>
          </Step>

          {/* 

            THE VIDEO

          */}

          <Step>
            <div className="fill fill-video" />
          </Step>
          <Step data={STEP.VIDEO}>
            <section className="step step-video">
              <Video />
            </section>
          </Step>
        </Scrollama>

        <style jsx>{`
          main {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: repeat(6, 100vh);
          }
          section {
            scroll-snap-align: start;
            grid-column: 1/5;
          }
          .full {
            min-height: 100vh;
          }

          .fill {
            grid-column: 1/5;
            z-index: 1;
            width: 100%;
            height: 100%;
          }
          .trail {
            z-index: 2;
            grid-row: 1/7;
            grid-column: 1/5;
          }
          .step {
            z-index: 3;
          }

          .fill-hero {
            grid-row: 1/2;
            background-color: var(--primary-lightest);
          }
          .step-hero {
            grid-row: 1/2;
            height: 100vh;
          }

          .fill-online-giants {
            grid-row: 2/3;
            background-color: var(--primary);
          }
          .step-online-giants {
            grid-row: 2/3;
          }

          .fill-local-stores {
            grid-row: 3/4;
            background-color: var(--alternate-lighter-a);
          }
          .step-local-stores {
            grid-row: 3/4;
          }

          .fill-online-visibility {
            grid-row: 4/5;
            background-color: var(--chakra-colors-teal-400);
          }
          .step-online-visibility {
            grid-row: 4/5;
          }

          .fill-solution {
            grid-row: 5/6;
            background-color: var(--primary);
          }
          .step-solution {
            grid-row: 5/6;
          }

          .fill-video {
            grid-row: 6/7;
            background-color: var(--alternate);
          }
          .step-video {
            grid-row: 6/7;
            height: 100vh;
          }

          .fill-innovative {
            grid-row: 7/8
            background-color: var(--alternate);
          }
          .step-innovative {
            grid-row: 7/8
          }

          @media screen and (max-width: 800px) {
            section {
              grid-template-columns: [left-edge] 1em [left-content] 2fr [right-content] 1em [right-edge];
            }
            .demo {
              display: none;
            }
            .stepDemo {
              display: none;
            }
          }
          .clickthrough {
            pointer-events: none;
          }
        `}</style>
      </main>
    </Layout>
  );
};

Page.getLayout = (page) => page;

export default Page;
